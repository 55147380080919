import Vue from 'vue'
import Vuex from "vuex";
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/style/elementui.less";
import 'ant-design-vue/dist/antd.css';
import "./assets/style/common.less";
import Store from "store";
import store from "./store/modules/menuInfo.js";
import echarts from 'echarts'
import TreeTable from 'vue-table-with-tree-grid'
import '../src/utils/rem.js'
import download from 'downloadjs'
import "./utils/dialogdrag.js"
import VueQuillEditor from 'vue-quill-editor'
import Moment from 'moment'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)

Vue.component('tree-table', TreeTable)

// import store from "./store";

import {
  getRouterData
} from "./router";

Vue.prototype.$download = download //VUE不预览直接下载PDF、下载图片文件
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$sto = Store;

Moment.locale('zh-cn')
Vue.prototype.$moment = Moment; // 时间转换插件

Vue.use(ElementUI)
Vue.use(Vuex);

Vue.prototype.$vuex = store


new Vue({
  store,
  router: getRouterData(),
  render: h => h(App),
}).$mount('#app')