
// 常量路由
export const constantRouterMap = [
  { path: '*', redirect: '/404' },
  { path: '/login', name: 'login', component: 'login/login.vue', meta: { title: "登录" } },
  { path: '/sign-up', name: 'signUp', component: 'login/signUp.vue', meta: { title: "注册" } },
  { path: '/create-com', name: 'createCom', component: 'login/createCom.vue', meta: { title: "创建公司" } },
  { path: '/404', name: '404', component: '404/404.vue', meta: { title: "找不到页面" } },
  { path: '/quoted/quotation', name: 'quotation', component: 'quoted/quotation', meta: { title: "报价单导出" }},
  { path: '/data/screen', name: 'screen', component: 'screen/dataScreen.vue', meta: { title: "数据大屏" }},
  // { path: '/dataScreen', name: 'dataScreen', component: 'dataScreen/index.html', meta: { title: "帮客可视化大数据统计" } },  // 数据大屏
  
]

// 动态路由
export const asyncRouterMap = [
  // 其他页面跳转路由  ---  待配置
  { path: '/service/service-test/detail-test', name: 'detailTest', component: 'service/detailTest.vue', meta: { title: "详情质检" } },
  { path: '/service/service-test/test-record', name: 'testRecord',  component: 'service/testRecord', meta: { title: "质检记录" } },
  
  // 店铺服务数据子路由
  { path: '/data/customer-service-data/customer-service-detail', name: 'serviceDetail', component: 'dataInfo/serviceDetail.vue', meta: { title: "店铺服务数据详情" } },
  // 系统店铺服务数据子路由
  { path: '/data/system-service-data/system-service-detail', name: 'systemSerDetail', component: 'dataInfo/systemSerDetail.vue', meta: { title: "系统店铺服务数据详情" } },
  // 店铺交易数据子路由
  { path: '/data/customer-trade-data/customer-trade-detail', name: 'tradeDataDetail', component: 'dataInfo/tradeDataDetail.vue', meta: { title: "店铺交易数据详情" } },
	
  // 接口返回路由
  { path: '/affair/affair-list', name: 'affair', component: 'affair/affairList.vue', meta: { title: "工单列表" } },

  { path: '/notice/update', name: 'versionUp', component: 'version/versionUp.vue', meta: { title: "上传新版本" } },

  { path: '/data/reference-sy', name: 'versionUp', component: 'dataInfo/ReferenceSY.vue', meta: { title: "系统基准值" } },
	{ path: '/data/attendance', name: 'attendance', component: 'dataInfo/attendanceData.vue', meta: { title: "考勤统计" } },
  { path: '/manage/company-list', name: 'company', component: 'manage/companyList.vue', meta: { title: "分公司设置" } },
  { path: '/manage/user-group', name: 'userGroup', component: 'manage/userGroup.vue', meta: { title: "部门设置" } },
  //员工列表调查问卷图表
  { path: '/manage/user-echarts', name: 'userCharts', component: 'manage/children/userEcharts.vue', meta: { title: "调查问卷统计" } },
  { path: '/manage/user-list', name: 'userList', component: 'manage/newUserList.vue', meta: { title: "员工列表" } },
  // { path: '/manage/user-list', name: 'userList', component: 'manage/userList.vue', meta: { title: "员工列表" } },
  { path: '/manage/role-list', name: 'roleList', component: 'manage/roleList.vue', meta: { title: "角色列表" } },
  { path: '/manage/quit', name: 'roleList', component: 'manage/quit.vue', meta: { title: "离职员工" } },

  // 项目设置
  { path: '/customer/project-setting', name: 'receiveList', component: 'customer/projectSet.vue', meta: { title: "项目设置" }},

  { path: '/customer/customer-pool', name: 'pool', component: 'customer/customerPool.vue', meta: { title: "客户中心" } },
  { path: '/customer/recovery', name: 'recovery', component: 'customer/recovery.vue', meta: { title: "客户回收站" } },

  { path: '/customer/customer-list', name: 'customerList', component: 'customer/customerList.vue', meta: { title: "总店铺列表" } },
  { path: '/customer/customer-list/subaccount-list', name: 'subaccount', component: 'customer/subaccount.vue', meta: { title: "总子账号管理" } },

  { path: '/customer/company-shop-list', name: 'companyShopList', component: 'customer/companyShopList.vue', meta: { title: "店铺中心" } },
  { path: '/customer/company-shop-list/subaccount-list', name: 'companySubaccount', component: 'customer/companySubaccount.vue', meta: { title: "分公司子账号管理" } },
  { path: '/customer/company-shop-list/subaccount-list/service', name: 'companySubSer', component: 'customer/companySubSer.vue', meta: { title: "指派客服" } },

  { path: '/customer/company-service-shop-list', name: 'serviceShopList', component: 'customer/serviceShopList.vue', meta: { title: "销售店铺列表" } },
  { path: '/customer/company-service-shop-list/subaccount-list', name: 'serviceSubaccount', component: 'customer/serviceSubaccount.vue', meta: { title: "子账号管理" } },


  { path: '/customer/return-sea', name: 'returnSea', component: 'customer/returnSea.vue', meta: { title: "打回公海设置" } },
  { path: '/customer/category', name: 'category', component: 'customer/category.vue', meta: { title: "类目设置" } },
  { path: '/customer/customer-sea', name: 'sea', component: 'customer/customerSea.vue', meta: { title: "线索公海" } },
  { path: '/customer/my-customer', name: 'myCustomer', component: 'customer/myCustomer.vue', meta: { title: "我的客户" } },
  { path: '/customer/sales-leads', name: 'salesLeads', component: 'customer/salesLeads.vue', meta: { title: "销售机会" } },
  { path: '/customer/customer-mysea', name: 'mysea', component: 'customer/customerMySea.vue', meta: { title: "线索私海" } },

  { path: '/customer/new-order', name: 'customerOrder', component: 'customer/customerOrder.vue', meta: { title: "新签客户订单" } },    // 与续签订单合并
  { path: '/customer/renewal-order', name: 'renewalOrder', component: 'customer/renewalOrder.vue', meta: { title: "续签销售订单" } },  // 与新签订单合并

  { path: '/customer/total-order', name: 'totalOrder', component: 'customer/totalOrder.vue', meta: { title: "订单中心" } },         // 新签续签订单的合并

  { path: '/customer/shop-order', name: 'ShopOrder', component: 'customer/ShopOrder.vue', meta: { title: "店铺订单" } },

  { path: '/service/service-list', name: 'serviceList', component: 'service/serviceList.vue', meta: { title: "客服列表" } },
  { path: '/service/service-scheduling', name: 'scheduling', component: 'service/scheduling.vue', meta: { title: "客服排班" } },
  { path: '/service/shift-management', name: 'management', component: 'service/management.vue', meta: { title: "班次管理" } },
  { path: '/service/service-test', name: 'serviceTest', component: 'service/serviceTest.vue', meta: { title: "客服质检" } },
  { path: '/service/test-sort', name: 'testSort', component: 'service/testSort.vue', meta: { title: "质检分类" } },
  { path: '/service/test-setting', name: 'testSetting', component: 'service/testSetting.vue', meta: { title: "质检设置" } },
  { path: '/service/brand-problem', name: 'brandProblem', component: 'service/brandProblem.vue', meta: { title: "品牌质检分类" } },
  { path: '/service/appeal-record-service', name: 'appealRecord', component: 'service/appealRecordService.vue', meta: { title: "质检记录(客服)" } },  //  质检记录，申诉审批流
  { path: '/service/appeal-record-quality', name: 'appealRecord', component: 'service/appealRecordQuality.vue', meta: { title: "质检记录(质检)" } },  //  质检记录，申诉审批流
  { path: '/service/quality-warn', name: 'qualityWarn', component: 'service/qualityWarn.vue', meta: { title: "质检预警" } },  //  质检预警
  { path: '/service/cus-record-quality', name: 'cusRecord', component: 'service/cusRecordQuality.vue', meta: { title: "品牌方质检记录" } },
  { path: '/service/change-record', name: 'changeRecord', component: 'service/changeRecord.vue', meta: { title: "客服交班记录" } },
  { path: '/service/shop-problem', name: 'shopProblem', component: 'service/shopProblem.vue', meta: { title: "店铺问题记录" } },  //  质检记录

  { path: '/customer/service-deploy', name: 'serviceDeploy', component: 'service/serviceDeploy.vue', meta: { title: "客服调配" } },
  { path: '/service/deploy-setting', name: 'deploySetting', component: 'service/deploySetting.vue', meta: { title: "客服爆量设置" } },

   // 字典值设置
  { path: '/dictionaries/dict-setting', name: 'dictSetting', component: 'dictionaries/dictSetting.vue', meta: { title: "字典值设置" } },  //  字典值设置
  { path: '/dictionaries/dict-setting/:id', name: 'dictDetailSetting', component: 'dictionaries/dictDetailSetting.vue', meta: { title: "字典明细设置" } },  //  字典明细设置
  

  { path: '/data/system-service-data', name: 'serviceData', component: 'dataInfo/serviceData.vue', meta: { title: "店铺服务数据" } },
  { path: '/data/customer-service-data', name: 'systemServiceData', component: 'dataInfo/systemServiceData.vue', meta: { title: "系统店铺服务数据" } },
  // { path: '/data/customer-service-data', name: 'serviceData', component: 'dataInfo/serviceData.vue', meta: { title: "店铺服务数据" } },
  // { path: '/data/system-service-data', name: 'systemServiceData', component: 'dataInfo/systemServiceData.vue', meta: { title: "系统店铺服务数据" } },
  { path: '/data/customer-trade-data', name: 'tradeData', component: 'dataInfo/tradeData.vue', meta: { title: "店铺交易数据" } },
  { path: '/data/service-online-data', name: 'onlineData', component: 'dataInfo/onlineData.vue', meta: { title: "客服在班数据" } },

  { path: '/data/customer-service-analysis', name: 'serviceAnalysis', component: 'dataInfo/serviceAnalysis.vue', meta: { title: "店铺服务分析" } },
    // 店铺服务分析子路由
  { path: '/data/customer-service-analysis/shop-kpi', name: 'shopKPI', component: 'dataInfo/shopKPI.vue', meta: { title: "店铺KPI" } },
  { path: '/data/customer-service-analysis/service-kpi', name: 'serviceKPI', component: 'dataInfo/serviceKPI.vue', meta: { title: "客服KPI" } },
  { path: '/data/customer-service-analysis/service-kpi/detail', name: 'serviceKPIDetail', component: 'dataInfo/serviceKPIDetail.vue', meta: { title: "客服KPI详情" } },

  { path: '/data/customer-service-satisfied', name: 'serviceSatisfied', component: 'dataInfo/serviceSatisfied.vue', meta: { title: "满意度分析" } },
  // 满意度分析子路由
  { path: '/data/customer-service-satisfied/source', name: 'satisfiedSource', component: 'dataInfo/satisfiedSource.vue', meta: { title: "满意度数据源" } },

  { path: '/system/operatelog', name: 'operatelog', component: 'system/operatelog.vue', meta: { title: "操作日志" } },

  //个人档案，公司档案
  { path: '/personal-center', name: 'persCenter', component: 'login/persCenter.vue', meta: { title: "个人档案" } },
  // { path: '/personal-center', name: 'persCenter', component: 'login/personCenter.vue', meta: { title: "个人档案" } },
  

  { path: '/notice/noticedetail', name: 'noticeList', component: 'notice/noticeList.vue', meta: { title: "公告设置" } },

  { path: '/message-center-notice', name: 'messageNotice', component: 'notice/messageNotice.vue', meta: { title: "消息通知" } },

  // 知识库 自动回复
  { path: '/library/auto-reply-list', name: 'autoReply', component: 'auto/autoReply.vue', meta: { title: "店铺回复" } },
  // 自动回复》》》问答管理
  { path: '/library/auto-reply-list/qa-manage', name: 'qaManage', component: 'auto/qaManage', meta: { title: "问答管理" } },
  // 自动回复》》》问答分类
  // { path: '/library/auto-reply-list/qa-category', name: 'qaCategory', component: 'auto/qaCategory', meta: { title: "问答分类" } },
  // 自动回复》》》辅助会话设置
  // { path: '/library/auto-reply-list/support', name: 'support', component: 'auto/support', meta: { title: "辅助会话设置" } },
  // 新增菜单项----分类管理
  { path: '/library/auto-reply-manage', name: 'autoManage', component: 'auto/autoManage.vue', meta: { title: "分类管理" } },

  // 首页
  { path: '/index', name: 'home', component: 'home/home', meta: { title: "首页" } },
  
  // 财务 进账阶段
  { path: '/finance/income', name: 'Income', component: 'money/income', meta: { title: "进账" }},
  // 财务 出账阶段
  { path: '/finance/payout', name: 'Payout', component: 'money/payout', meta: { title: "出账" } },
  // 财务 对账阶段
  { path: '/finance/check', name: 'Check', component: 'money/check', meta: { title: "对账" } },
  // 财务 结算阶段
  { path: '/finance/settle', name: 'Settle', component: 'money/settle', meta: { title: "结算" } },
  // 财务 次月续费预测
  { path: '/finance/future', name: 'Future', component: 'money/future', meta: { title: "次月续费预测" } },
  // 财务 销售对账列表
  { path: '/finance/sale-account', name: 'saleAccount', component: 'money/saleAccount', meta: { title: "销售对账" } },
  
  // 头部 云文档
  { path: '/header/cloud', name: 'Cloud', component: 'header/cloud', meta: { title: "云文档" } },
  
  // 报价
  { path: '/quoted/quotes', name: 'Quotes', component: 'quoted/quotes', meta: { title: "报价中心" }},
  { path: '/quoted/newCus', name: 'NewCus', component: 'quoted/newCus', meta: { title: "客户报价" } }, 
  { path: '/quoted/quoted-setting', name: 'quotedSetting', component: 'quoted/setting', meta: { title: "报价设置" }},
  { path: '/quoted/base-services', name: 'baseServices', component: 'quoted/baseServices', meta: { title: "基础服务填报" }},
  { path: '/quoted/setting-content', name: 'settingContent', component: 'quoted/settingContent', meta: { title: "配置服务内容" }},
  { path: '/quoted/appoint', name: 'appointStand', component: 'quoted/appoint', meta: { title: "约定数据标准" }},
  { path: '/quoted/confirm', name: 'confirmPage', component: 'quoted/confirm', meta: { title: "确认报价" }},
  { path: '/quoted/complete', name: 'completePage', component: 'quoted/complete', meta: { title: "报价完成" }},
  { path: '/quoted/create-order', name: 'createOrder', component: 'quoted/createOrder', meta: { title: "生成订单" } },
  
  // 自定义报表
  { path: '/custom-report/report-setting/:id', name: 'reportSetting', component: 'report/setting', meta: { title: "设置报表" }},
  { path: '/custom-report/report-detail/:id', name: 'reportDetail', component: 'report/reportDetail', meta: { title: "报表详情" }},
  { path: '/custom-report/report', name: 'customeReport', component: 'report/customeReport', meta: { title: "自定义报表" }},
  
  // 派单 ( 平台 )
  { path: '/assign-order/assign-list', name: 'assignOrder', component: 'assignOrder/assignOrderList', meta: { title: "派单中心" }},
  { path: '/assign-order/assign-list/assign-one', name: 'assignOne', component: 'assignOrder/assignOne', meta: { title: "店铺填报" }},
  { path: '/assign-order/assign-list/assign-two', name: 'assignTwo', component: 'assignOrder/assignTwo', meta: { title: "供应商填报" }},
  { path: '/assign-order/assign-list/assign-three', name: 'assignThree', component: 'assignOrder/assignThree', meta: { title: "生成订单" } },
  
  // 接单（公司）
  { path: '/assign-order/receive-list', name: 'assignOrder', component: 'assignOrder/receiveList', meta: { title: "接单中心" } },
  
  // 工单
  { path: '/work-order/newadd', name: 'newPage', component: 'workOrder/newAddPage', meta: { title: "新建工单类型" } },
  { path: '/work-order/new-examine', name: 'examinePage', component: 'workOrder/examineAddPage', meta: { title: "审批工单" }},
  { path: '/work-order/setting', name: 'settingPage', component: 'workOrder/settingPage', meta: { title: "工单设置" }},
  { path: '/work-order/edit', name: 'editPage', component: 'workOrder/editPage', meta: { title: "工单编辑" }},
  { path: '/work-order/task-list', name: 'taskList', component: 'workOrder/taskList', meta: { title: "任务工单" }},
  { path: '/work-order/approve', name: 'editPage', component: 'workOrder/approve.vue', meta: { title: "审批工单" }},
  { path: '/work-order/task', name: 'editPage', component: 'workOrder/task.vue', meta: { title: "任务工单" }},
  { path: '/work-order/task/launch-task', name: 'editPage', component: 'workOrder/launchTask.vue', meta: { title: "发起任务" }},
  { path: '/work-order/approve/launch-approve', name: 'editPage', component: 'workOrder/launchApprove.vue', meta: { title: "发起审批" } },
  { path: '/work-order/scene', name: 'scenePage', component: 'workOrder/scene.vue', meta: { title: "应用场景审批" }},
  
  // 员工画像
  { path: '/portrait/attendance', name: 'attendance', component: 'portrait/attendance.vue', meta: { title: "员工出勤" }},
  { path: '/portrait/performance', name: 'performance', component: 'portrait/performance.vue', meta: { title: "绩效设置" }},
  { path: '/portrait-performanceSet', name: 'performanceSet', component: 'portrait/performanceSetting.vue', meta: { title: "产能绩效设置" }},
  { path: '/portrait-commissionSet', name: 'commissionSet', component: 'portrait/commissionSetting.vue', meta: { title: "提成绩效设置" }},
  { path: '/portrait/wages', name: 'wages', component: 'portrait/wages.vue', meta: { title: "工资项" }},
  { path: '/portrait/serviceLevel', name: 'serviceLevel', component: 'portrait/serviceLevel.vue', meta: { title: "客服等级设置" }},
  { path: '/portrait/overview', name: 'overview', component: 'portrait/overview.vue', meta: { title: "客服画像概览" }},
  { path: '/portrait/calculation', name: 'calculation', component: 'portrait/incomeCalculation.vue', meta: { title: "收入测算" }},

  // 微信聊天机器人配置
  { path: '/wechat/wechat-robot', name: 'wechatRobot', component: 'wechat/wechatRobot.vue', meta: { title: "微信提醒设置" }},
  { path: '/wechat/wechat-robot/setting', name: 'wechatRobotSet', component: 'wechat/wechatRobotSet.vue', meta: { title: "微信提醒设置" }},

  //帮助中心
  { path: '/help/help-center', name: 'helpCenter', component: 'notice/helpCenter.vue', meta: { title: "帮助中心" }},

  // 帮客官网入驻信息管理
  { path: '/manage/settled-info', name: 'settledInfo', component: 'manage/settledInfo.vue', meta: { title: "入驻信息" }},
]