import fetch from "./xhr/index";

/**
 * 根据公司获取公司管理员
 * @parama
 */
 export const selectNotice = param => fetch("GET", "/cms/orderDistribute/selectNotice", param);
 
/**
 * 客户分享-创建链接：获取邀请码
 * @parama
 */
 export const getShareCode = param => fetch("GET", "/ums/umsCustomerCode/customerCode", param);

/**
 * 类目下拉
 * @param
 */
export const selectCategory = param => fetch("GET", "/cms/cmsPlatformManage/categoryList", param);

/**
 * 平台下拉
 * @param
 */
export const selectPlatform = param => fetch("GET", "/cms/cmsPlatformManage/plateList", param);

/**
 * 字典明细设置-删除
 * @param
 */
export const dictDetailDelete = param => fetch("GET", "/element/sysElement/deleteElementDetail", param);

/**
 * 字典明细设置-新增
 * @param
 */
export const dictDetailAdd = param => fetch("POST", "/element/sysElement/addElementDetail", param);

/**
 * 字典值设置-删除
 * @param
 */
export const dictdelete = param => fetch("GET", "/element/sysElement/deleteElement", param);

/**
 * 字典值设置-新增
 * @param
 */
export const dictAdd = param => fetch("POST", "/element/sysElement/addElement", param);

/**
 * 字典值设置-列表
 * @param
 */
export const dictSetList = param => fetch("GET", "/element/sysElement/getList", param);

/**
 * 通知人下拉
 * @param
 */
 export const noticePeople = (param) => fetch("POST", `/cms/cmsCustomerOrder/noticeList`, param);

/**
 * 删除平台类目
 * @param
 */
 export const delPlat = (param, routeParam) => fetch("GET", `/cms/cmsPlatformManage/deletePlat/${routeParam}`, param);

/**
 * 新建平台类目
 * @param
 */
 export const createPlat = param => fetch("POST", "/cms/cmsPlatformManage/createPlat", param);

/**
 * 报价单设置-批量修改设置
 * @param
 */
export const deleteDictDetail = param => fetch("GET", "/element/sysElement/deleteElementDetail", param);

/**
 * 报价单设置-批量修改设置
 * @param
 */
export const addPlatformBtn = param => fetch("GET", "/element/sysElement/addQuotation", param);

/**
 * 报价单设置-批量修改设置
 * @param
 */
export const quotedSettingEdit = param => fetch("POST", "/element/sysElement/batchUpdateElementDetail", param);

/**
 * 字典值接口
 * @param
 */
export const quotedSettingDetail = param => fetch("GET", "/element/sysElement/getElementByUuids", param);

/**
 * 字典值接口
 * @param
 */
export const commonDictSys = param => fetch("GET", "/element/sysElement/getDetailList", param);

/**
 * 省市区详情
 * @param
 */
export const sysAreaDetail = param => fetch("GET", "/sys/sysArea/detail", param);

/**
 * 省市区菜单
 * @param
 */
export const sysArea = param => fetch("GET", "/sys/sysArea/list", param);

/**
 * 工作日志
 * @param
 */
export const workLog = param => fetch("GET", "/cms/cmsWorkLog/list", param);

/**
 * 点击已读
 * @param
 */
export const readOk = param => fetch("GET", "/sys/sysInfo/get", param);

/**
 * 未读消息
 * @param
 */
export const noReadNum = param => fetch("GET", "/sys/sysInfo/countNoRead", param);

/**
 * 根据ids获取聊天记录
 * @param
 */
export const getChatHisByIds = param => fetch("GET", "/sys/sysInfo/getChatHisByIds", param);

/**
 * 上传版本更新
 * @param
 */
export const versionUpdate = param => fetch("upload", "/desktop/uploadFiletoSpeci", param);

/**
 * 获取版本更新记录
 * @param
 */
export const versionList = param => fetch("POST", "/desktop/ListHistory", param);

/**
 * 获取店铺详情
 * @param
 */
export const getShopDetail = param => fetch("GET", "/quality/qualityRecord/getShopDetail", param);

/**
 * 获取平台类目 -- 下拉
 * @param
 */
export const getPlatList = param => fetch("GET", "/cms/cmsPlatformManage/list", param);

/**
 * 查看公告
 * @param
 */
export const noticeDetail = (param, routeParam) => fetch("GET", `/sys/sysInfo/get/${routeParam}`, param);

/**
 * 添加公告
 * @param
 */
export const noticeAdd = (param) => fetch("POST", `/sys/sysInfo/create`, param);

/**
 * 获取公告列表
 * @param
 */
export const noticeList = (param) => fetch("POST", `/sys/sysInfo/select`, param);

/**
 * 根据角色获取对应的用户
 * 角色类型 1总部运营 2公司管理员 3销售 4客服
 * @param
 */
export const getRoleByType = (param, routeParam) => fetch("GET", `/admin/getRoleByType/${routeParam}`, param);

/**
 * 获取所属客户下拉
 * @param
 */
export const getCustList = (param) => fetch("POST", `/cms/cmsCustomer/getCustomerList`, param);

/**
 * 获取所属店铺下拉
 * @param
 */
export const getShopList = (param) => fetch("POST", `/cms/cmsShop/shopList`, param);

/**
 * 获取所有部门下拉
 * @param
 */
export const commonDepartAll = (param, routeParam) => fetch("GET", `/depart/listAll/${routeParam}`, param);

/**
 * 获取所有部门及部门下人员
 * @param
 */
export const commonDepartPersonAll = (param) => fetch("GET", `/depart/list`, param);

/**
 * 根据部门id(,分割)获取对应人员信息
 * @param
 */
export const commonDepartUserList = (param) => fetch("GET", '/depart/userList', param);

/**
 * 获取所有角色下拉
 * @param
 */
export const commonAllRole = (param) => fetch("GET", `/role/allRole`, param);
/**
 * 字典值接口
 * 字典类型 0所有 1客户级别 2客户来源 3公海关系 4销售阶段 5订单状态 6所属行业 8打标 12业务类型
 * @param
 */
export const commonDict = (param, routeParam) => fetch("GET", `/sys/dict/${routeParam}`, param);

/**
 * oss获取签名
 * @param
 */
export const getOssKey = param => fetch("GET", "/aliyun/oss/policy", param);

/**
 * 登录接口
 * @param
 */
export const login = param => fetch("POST", "/admin/plateLogin", param);
/**
 * 导航菜单接口
 * @param
 */
export const userData = param => fetch("GET", "/admin/info", param);
/**
 * 用户信息接口
 * @param
 */
export const userInfo = (param, routeParam) => fetch("GET", `/admin/${routeParam}`, param);
/**
 * 修改用户密码接口
 * @param
 */
export const editPassword = (param, routeParam) => fetch("GET", `/admin/updatePassword/${routeParam}`, param);

// /**
//  * 消息列表
//  * @param
//  */
// export const infoList = param => fetch("POST", `/sys/sysInfo/select?type=${param.type}`, param);

/**
 * 消息列表
 * @param
 */
export const infoList = param => fetch("POST", `/sys/sysInfo/selectByType`, param);

/**
 * 更改头像
 * @param
 */
export const changeIcon = param => fetch("POST", `/admin/uploadUserLogo?logo=${param.logo}`, param);

/**
 * 查询员工/人员
 * @param
 */
export const personList = param => fetch("POST", `/admin/list`, param);

/**
 * 消息--一键已读
 * @param
 */
export const allRead = param => fetch("POST", `/sys/sysInfo/allRead`, param);

/**
 * 帮助中心-列表
 * @param
 */
export const helpList = param => fetch("GET", `/sys/sysWebsiteLiterature/list`, param);

/**
 * 帮助中心-新增
 * @param
 */
export const Addhelp = param => fetch("POST", `/sys/sysWebsiteLiterature/addWebsiteLiterature`, param);

/**
 * 帮助中心-编辑/删除
 * @param
 */
export const EditDeletehelp = param => fetch("POST", `/sys/sysWebsiteLiterature/updateWebsiteLiterature`, param);

/**
 * 帮助中心-详情
 * @param
 */
export const helpDetail = param => fetch("GET", `/sys/sysWebsiteLiterature/details`, param);